import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Container, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import  PrintIcon  from '@mui/icons-material/Print';
const B2bInvoice = () => {
  const [formData, setFormData] = useState({
    partyName: '',
    voucherNo: '',
    phone: '',
    date: '',
    opening: { gFine: 0, sFine: 0, cash: 0 },
    items: [],
    cashPayment: 0.00,
    closing: { gFine: 0, sFine: 0, cash: 0 }
  });

  const [newItem, setNewItem] = useState({
    particulars: '',
    grossWt: 0,
    netWt: 0,
    tPlusW: 0,
    fineWt: 0,
    rate: 0, // Added Rate
    totalAmt: 0, // Ensure initial state is number 0, not string
    material: '' // New field: 'gold' or 'silver'

  });

  const [editingIndex, setEditingIndex] = useState(-1); // -1 means not editing
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, warning, info

  const calculateFineWeight = (netWt, tPlusW) => {
    if (!netWt || !tPlusW) return '0.000'; // Handle potential NaN
    return (Number(netWt) * (Number(tPlusW) / 100)).toFixed(3);
  };

  const calculateTotalAmount = (fineWt, rate) => {
    if (!fineWt || !rate) return 0;
    return (Number(fineWt) * Number(rate)).toFixed(2);
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    const updatedItem = {
      ...newItem,
      [name]: name === 'particulars' || name === 'material' ? value : Number(value)
    };
  
    if (name === 'netWt' || name === 'tPlusW') {
      updatedItem.fineWt = calculateFineWeight(
        name === 'netWt' ? value : newItem.netWt,
        name === 'tPlusW' ? value : newItem.tPlusW
      );
      updatedItem.totalAmt = calculateTotalAmount(updatedItem.fineWt, newItem.rate); // Recalculate total amount when fineWt changes
    } else if (name === 'rate') {
      updatedItem.totalAmt = calculateTotalAmount(newItem.fineWt, value); // Recalculate total amount when rate changes
    }
  
    setNewItem(updatedItem);
  };
  
  const addItem = () => {
    if (newItem.particulars && newItem.grossWt > 0 && newItem.netWt >= 0 && newItem.tPlusW >= 0 && newItem.rate >= 0 && newItem.material) {
      const updatedItems = [...formData.items, newItem];
      const totalFineWeight = updatedItems.reduce((acc, item) => acc + parseFloat(item.fineWt), 0);
      
      setFormData((prevData) => {
        let updatedData = { ...prevData };
  
        if (newItem.material === 'gold') {
          updatedData = {
            ...prevData,
            items: updatedItems,
            closing: {
              ...prevData.closing,
              gFine: prevData.opening.gFine + (prevData.closing.gFine - prevData.opening.gFine) + totalFineWeight,
            }
          };
        } else if (newItem.material === 'silver') {
          updatedData = {
            ...prevData,
            items: updatedItems,
            closing: {
              ...prevData.closing,
              sFine: prevData.opening.sFine + (prevData.closing.sFine - prevData.opening.sFine) + totalFineWeight,
            }
          };
        }
  
        return updatedData;
      });
  
      // Reset the newItem state for the next input
      setNewItem({
        particulars: '',
        grossWt: 0,
        netWt: 0,
        tPlusW: 0,
        fineWt: 0,
        rate: 0,
        totalAmt: 0,
        material: '' // Reset material
      });
  
      setSnackbarMessage('Item added successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('Please fill in all item details correctly (Particulars, Gross Wt, Net Wt, T+W, Rate, Material).');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };
  
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = Number(value);
  
    setFormData((prevData) => {
      let updatedData = { ...prevData };
  
      if (name === 'cashPayment') {
        updatedData = {
          ...prevData,
          [name]: numericValue,
          closing: {
            ...prevData.closing,
            cash: prevData.opening.cash + numericValue
          }
        };
      } else if (name.startsWith('opening.')) {
        const balanceType = name.split('.')[1]; // Either 'gFine', 'sFine', or 'cash'
        const updatedOpening = { ...prevData.opening, [balanceType]: numericValue };
  
        updatedData = {
          ...prevData,
          opening: updatedOpening,
          closing: {
            ...prevData.closing,
            [balanceType]: numericValue + prevData.closing[balanceType] - prevData.opening[balanceType] // Adjust dynamically
          }
        };
      } else {
        updatedData = { ...prevData, [name]: value };
      }
  
      return updatedData;
    });
  };
  
  
  const generatePDF = () => {
    const doc = new jsPDF('potrait', 'mm', 'a5'); // Set A3 size in landscape mode
    doc.setFont('helvetica');
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 15;
    let currentY = margin;
  
    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    doc.text('SALE ESTIMATE VOUCHER', pageWidth / 2, currentY + 10, { align: 'center' });
    currentY += 18;
  
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text('(Original)', pageWidth / 2, currentY + 5, { align: 'center' });
    currentY += 18;
  
    // Party Details
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Party Details', margin, currentY + 6);
    currentY += 10;
  
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Party: ${formData.partyName}`, margin, currentY + 6);
    doc.text(`Voucher No: ${formData.voucherNo}`, pageWidth - 120, currentY + 6);
    currentY += 6;
    doc.text(`Phone: ${formData.phone}`, margin, currentY + 6);
    doc.text(`Date: ${formData.date}`, pageWidth - 120, currentY + 6);
    currentY += 18;
  
    // Opening Balance
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Opening Balance', margin, currentY + 6);
    currentY += 10;
  
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `G. Fine: ${formData.opening.gFine} | S. Fine: ${formData.opening.sFine} | Cash: ${formData.opening.cash}`,
      margin,
      currentY + 6
    );
    currentY += 18;
  
    // Items Table
    doc.autoTable({
      startY: currentY,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1, lineColor: [0, 0, 0] },
      bodyStyles: { fontSize: 12, cellPadding: 6, lineWidth: 0.1, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
      styles: { font: 'helvetica', fontSize: 12 },
      columnStyles: {
        0: { halign: 'center', cellWidth: 15 },
        1: { halign: 'left', cellWidth: 50 },
        2: { halign: 'right', cellWidth: 25 },
        3: { halign: 'right', cellWidth: 25 },
        4: { halign: 'right', cellWidth: 20 },
        5: { halign: 'right', cellWidth: 25 },
        6: { halign: 'right', cellWidth: 30 },
        7: { halign: 'right', cellWidth: 35 },
      },
      head: [['Sr', 'Particulars', 'Gross Wt.', 'Net Wt.', 'T+W (%)', 'Fine Wt.', 'Rate', 'Total Amt']],
      body: formData.items.map((item, index) => [
        index + 1,
        item.particulars,
        item.grossWt.toFixed(3),
        item.netWt.toFixed(3),
        item.tPlusW.toFixed(2),
        item.fineWt,
        item.rate.toFixed(2),
        Number(item.totalAmt).toFixed(2),
      ]),
      theme: 'plain',
    });
  
    currentY = doc.lastAutoTable.finalY + 20;
  
    // Totals Section
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Totals', margin, currentY + 6);
    currentY += 10;
  
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Gross Wt: ${formData.items.reduce((acc, item) => acc + item.grossWt, 0).toFixed(3)}`, margin, currentY + 6);
    doc.text(`Net Wt: ${formData.items.reduce((acc, item) => acc + item.netWt, 0).toFixed(3)}`, pageWidth - 180, currentY + 6);
    currentY += 6;
    doc.text(`Fine Wt: ${formData.items.reduce((acc, item) => acc + parseFloat(item.fineWt), 0).toFixed(3)} (G Fine)`, margin, currentY + 6);
    doc.setFont('helvetica', 'bold');
    doc.text(`Grand Total: ${formData.items.reduce((acc, item) => acc + parseFloat(item.totalAmt), 0).toFixed(2)}`, pageWidth - 180, currentY + 6);
    currentY += 6;
    doc.setFont('helvetica', 'normal');
    doc.text(`Cash Payment: ${formData.cashPayment.toFixed(2)}`, margin, currentY + 6);
    currentY += 18;
  
    // Closing Balance Section
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Closing Balance', margin, currentY + 6);
    currentY += 10;
  
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `G. Fine: ${formData.closing.gFine} | S. Fine: ${formData.closing.sFine} | Cash: ${formData.closing.cash}`,
      margin,
      currentY + 6
    );
  
    // Footer
    currentY = pageHeight - 25;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'italic');
    doc.text('Terms & Conditions apply.', margin, currentY);
    doc.text('Authorized Signature: ______________________', pageWidth - 120, currentY);
  
    doc.save('sale-estimate-voucher-a3.pdf');
  };
  
  const printPDF = () => {
    const doc = new jsPDF();
    doc.setFont('helvetica');

    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let currentY = margin;

    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.text('SALE ESTIMATE VOUCHER', pageWidth / 2, currentY + 10, { align: 'center' });
    currentY += 12;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('(Original)', pageWidth / 2, currentY + 5, { align: 'center' });
    currentY += 15;

    // Party Details
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Party Details', margin, currentY + 6);
    currentY += 8;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Party: ${formData.partyName}`, margin, currentY + 6);
    doc.text(`Voucher No: ${formData.voucherNo}`, pageWidth - 70 , currentY + 6);
    currentY += 6;
    doc.text(`Phone: ${formData.phone}`, margin, currentY + 6);
    doc.text(`Date: ${formData.date}`, pageWidth - 70, currentY + 6);
    currentY += 12;

    // Opening Balance
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Opening Balance', margin, currentY + 6);
    currentY += 8;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `G. Fine: ${formData.opening.gFine} | S. Fine: ${formData.opening.sFine} | Cash: ${formData.opening.cash}`,
      margin,
      currentY + 6
    );
    currentY += 15;

    // Items Table
    doc.autoTable({
      startY: currentY,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1, lineColor: [0, 0, 0] },
      bodyStyles: { fontSize: 10, cellPadding: 4, lineWidth: 0.1, lineColor: [0, 0, 0], fillColor:  [255, 255, 255] },
      styles: { font: 'helvetica', fontSize: 10 },
      columnStyles: {
        0: { halign: 'center', cellWidth: 12 },
        1: { halign: 'left', cellWidth: 50 },
        2: { halign: 'right', cellWidth: 20 },
        3: { halign: 'right', cellWidth: 20 },
        4: { halign: 'right', cellWidth: 15 },
        5: { halign: 'right', cellWidth: 20 },
        6: { halign: 'right', cellWidth: 25 },
        7: { halign: 'right', cellWidth: 30 },
      },
      head: [['Sr', 'Particulars', 'Gross Wt.', 'Net Wt.', 'T+W (%)', 'Fine Wt.', 'Rate', 'Total Amt']],
      body: formData.items.map((item, index) => [
        index + 1,
        item.particulars,
        item.grossWt.toFixed(3),
        item.netWt.toFixed(3),
        item.tPlusW.toFixed(2),
        item.fineWt,
        item.rate.toFixed(2),
        Number(item.totalAmt).toFixed(2),
      ]),
      theme: 'plain',
    });

    currentY = doc.lastAutoTable.finalY + 10;

    // Totals Section
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Totals', margin, currentY + 6);
    currentY += 8;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Gross Wt: ${formData.items.reduce((acc, item) => acc + item.grossWt, 0).toFixed(3)}`, margin, currentY + 6);
    doc.text(`Net Wt: ${formData.items.reduce((acc, item) => acc + item.netWt, 0).toFixed(3)}`, pageWidth - 140, currentY + 6);
    currentY += 6;
    doc.text(`Fine Wt: ${formData.items.reduce((acc, item) => acc + parseFloat(item.fineWt), 0).toFixed(3)} (G Fine)`, margin, currentY + 6);
    doc.setFont('helvetica', 'bold');
    doc.text(`Grand Total: ${formData.items.reduce((acc, item) => acc + parseFloat(item.totalAmt), 0).toFixed(2)}`, pageWidth - 140, currentY + 6);
    currentY += 6;
    doc.setFont('helvetica', 'normal');
    doc.text(`Cash Payment: ${formData.cashPayment.toFixed(2)}`, margin, currentY + 6);
    currentY += 12;

    // Closing Balance Section
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Closing Balance', margin, currentY + 6);
    currentY += 8;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `G. Fine: ${formData.closing.gFine} | S. Fine: ${formData.closing.sFine} | Cash: ${formData.closing.cash}`,
      margin,
      currentY + 6
    );

    // Footer
    currentY = doc.internal.pageSize.getHeight() - 20;
    doc.setFontSize(10);
    doc.setFont('helvetica', 'italic');
    doc.text('Terms & Conditions apply.', margin, currentY);
    doc.text('Authorized Signature: ______________________', pageWidth - 80, currentY);

    var popup = window.open('', '', 'width=600,height=400'); 

    // Create the PDF output and print
    doc.autoPrint();
    popup.document.write('<iframe width="100%" height="100%" src="' + doc.output('bloburl') + '" frameborder="0"></iframe>');
      };  
  const handleEditItem = (index) => {
    setNewItem(formData.items[index]);
    setFormData(prevFormData => {
      const updatedItems = [...prevFormData.items];
      updatedItems.splice(index, 1); // Remove item from the form data temporarily for editing
      return { ...prevFormData, items: updatedItems };
    });
    setEditingIndex(index); // Set the index being edited
  };


  const handleUpdateItem = () => {
    if (newItem.particulars && newItem.grossWt > 0 && newItem.netWt >= 0 && newItem.tPlusW >= 0 && newItem.rate >= 0) {

      const updatedItems = [...formData.items, newItem];
      const totalFineWeight = updatedItems.reduce((acc, item) => acc + parseFloat(item.fineWt), 0);

      setFormData(prevFormData => ({
        ...prevFormData,
        items: updatedItems,
        closing: {
          ...prevFormData.closing,
          gFine: totalFineWeight,
        }
      }));


      setNewItem({ // Reset newItem after editing
        particulars: '',
        grossWt: 0,
        netWt: 0,
        tPlusW: 0,
        fineWt: 0,
        rate: 0,
        totalAmt: 0 // Reset totalAmt to number 0
      });
      setEditingIndex(-1); // Reset editing index
      setSnackbarMessage('Item updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('Please fill in all item details correctly.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };


  const handleDeleteItem = (index) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    const totalFineWeight = updatedItems.reduce((acc, item) => acc + parseFloat(item.fineWt), 0);

    setFormData({
      ...formData,
      items: updatedItems,
      closing: {
        ...formData.closing,
        gFine: totalFineWeight,
      }
    });
    setSnackbarMessage('Item deleted successfully!');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  return (
<Container sx={{ p: 3, maxWidth: '100%' }}>
  <Paper sx={{ p: 3 }}>
    <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
      B2B Invoice
    </Typography>

    <Grid container spacing={2} mb={3}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Party Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField label="Party Name" fullWidth name="partyName" value={formData.partyName} onChange={handleInputChange} required />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField label="Voucher No" fullWidth name="voucherNo" value={formData.voucherNo} onChange={handleInputChange} required />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField label="Phone No" fullWidth name="phone" value={formData.phone} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          label="Date"
          type="date"
          fullWidth
          name="date"
          value={formData.date}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          required
        />
      </Grid>
    </Grid>

    <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Opening Balance</Typography>
    <Grid container spacing={2} mb={3}>
      <Grid item xs={4} sm={4}>
        <TextField label="G Fine" type="number" fullWidth name="opening.gFine" value={formData.opening.gFine} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={4} sm={4}>
        <TextField label="S Fine" type="number" fullWidth name="opening.sFine" value={formData.opening.sFine} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={4} sm={4}>
        <TextField label="Cash" type="number" fullWidth name="opening.cash" value={formData.opening.cash} onChange={handleInputChange} />
      </Grid>
    </Grid>

    <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Items</Typography>
    <Grid container spacing={2} mb={2} alignItems="flex-start">
 


<Grid item xs={12} sm={3}>
<FormControl fullWidth>
  <InputLabel id="material-label">Material</InputLabel>
  <Select
    labelId="material-label"
    id="material-select"
    name="material"
    value={newItem.material}
    onChange={handleItemChange}
    label="Material"
  >
    <MenuItem value="">Select Material</MenuItem>
    <MenuItem value="gold">Gold</MenuItem>
    <MenuItem value="silver">Silver</MenuItem>
  </Select>
</FormControl>

     </Grid>

      <Grid item xs={12} sm={3}>
        <TextField label="Particulars" fullWidth name="particulars" value={newItem.particulars} onChange={handleItemChange} required />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label="Gross Wt" type="number" fullWidth name="grossWt" value={newItem.grossWt} onChange={handleItemChange} required />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label="Net Wt" type="number" fullWidth name="netWt" value={newItem.netWt} onChange={handleItemChange} required />
      </Grid>
      <Grid item xs={6} sm={1}>
        <TextField label="T+W (%)" type="number" fullWidth name="tPlusW" value={newItem.tPlusW} onChange={handleItemChange} required />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label="Fine Wt" type="number" fullWidth value={newItem.fineWt} disabled />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label="Rate" type="number" fullWidth name="rate" value={newItem.rate} onChange={handleItemChange} required />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label="Total Amt" type="number" fullWidth value={newItem.totalAmt} disabled />
      </Grid>
      <Grid item xs={12}>
        {editingIndex !== -1 ? (
          <Button variant="contained" onClick={handleUpdateItem} fullWidth>
            Update Item
          </Button>
        ) : (
          <Button variant="contained" onClick={addItem} fullWidth>
            Add Item
          </Button>
        )}
      </Grid>
    </Grid>

    <Table component={Paper} sx={{ mb: 3 }}>
      <TableHead>
        <TableRow>
          <TableCell>Sr</TableCell>
          <TableCell>Particulars</TableCell>
          <TableCell align="right">Gross Wt.</TableCell>
          <TableCell align="right">Net Wt.</TableCell>
          <TableCell align="right">T+W (%)</TableCell>
          <TableCell align="right">Fine Wt.</TableCell>
          <TableCell align="right">Rate</TableCell>
          <TableCell align="right">Total Amt</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formData.items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.particulars}</TableCell>
            <TableCell align="right">{item.grossWt.toFixed(3)}</TableCell>
            <TableCell align="right">{item.netWt.toFixed(3)}</TableCell>
            <TableCell align="right">{item.tPlusW.toFixed(2)}</TableCell>
            <TableCell align="right">{item.fineWt}</TableCell>
            <TableCell align="right">{item.rate.toFixed(2)}</TableCell>
            <TableCell align="right">{Number(item.totalAmt).toFixed(2)}</TableCell>
            <TableCell align="center">
              <IconButton aria-label="edit" onClick={() => handleEditItem(index)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => handleDeleteItem(index)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={7} align="right" fontWeight="bold">Grand Total</TableCell>
          <TableCell align="right" fontWeight="bold">
            {formData.items.reduce((acc, item) => acc + parseFloat(item.totalAmt), 0).toFixed(2)}
          </TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <TextField label="Cash Payment" type="number" fullWidth name="cashPayment" value={formData.cashPayment} onChange={handleInputChange} sx={{ mb: 3 }} />

    <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Closing Balance</Typography>
    <Grid container spacing={2} mb={3}>
      <Grid item xs={4} sm={4}>
        <TextField label="G Fine" type="number" fullWidth value={formData.closing.gFine} disabled />
      </Grid>
      <Grid item xs={4} sm={4}>
        <TextField label="S Fine" type="number" fullWidth value={formData.closing.sFine} disabled />
      </Grid>
      <Grid item xs={4} sm={4}>
        <TextField label="Cash" type="number" fullWidth value={formData.closing.cash} disabled />
      </Grid>
    </Grid>

    <Grid container spacing={2} alignItems="center" justifyContent="center" mt={3} direction={{ xs: 'column', sm: 'row' }}>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          onClick={printPDF}
          sx={{
            backgroundColor: '#ff9800',
            color: 'white',
            borderRadius: '10px',
            width: '100%',
            '&:hover': { backgroundColor: '#f57c00' },
          }}
        >
          <PrintIcon sx={{ mr: 1 }} />
          Print Invoice
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" color="primary" onClick={generatePDF} sx={{
          backgroundColor: '#ff9800',
          color: 'white',
          borderRadius: '10px',
          width: '100%',
          '&:hover': { backgroundColor: '#f57c00' },
        }}>
          Download Invoice
        </Button>
      </Grid>
    </Grid>
  </Paper>

  <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000}
    onClose={handleSnackbarClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
      {snackbarMessage}
    </Alert>
  </Snackbar>
</Container>

  
  );
};

export default B2bInvoice;