import React, { useState, useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { Container, TextField, Button, Typography, Box, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Change this color to your preferred primary color
    },
  },
});

const LoginAuth = ({ children }) => {
    const location = useLocation();

    const currentRoute = location.pathname;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // Check if the user is already authenticated
    const auth = localStorage.getItem("auth");
    if (auth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    // Dummy authentication logic
    if (phoneNumber === "9839454477" && password === "suniljewellers@77") {
        setIsAuthenticated(true);
      localStorage.setItem("auth", "true");
      if (currentRoute === "/b2b") {
        history.push("/b2b");  // Go to /b2b if user was on that route
      } else {
        history.push("/invoice");  // Go to /invoice if user was on that route
      }    } else {
      alert("Invalid phone number or password");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("auth");
    history.push("/");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      {isAuthenticated ? (
        <>
          <Button variant="contained" color="primary" onClick={handleLogout} style={{ marginTop: "10px" }}>
            Logout
          </Button>
          {children}
        </>
      ) : (
        <Container maxWidth="xs">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Login
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              variant="outlined"
            />
            <TextField
              margin="normal"
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              fullWidth
              style={{ marginTop: "20px" }}
            >
              Login
            </Button>
          </Box>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default LoginAuth;
