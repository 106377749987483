import React from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import Pendants from "./Screens/ProductPage/Pendants/Pendants";
import Bracelets from "./Screens/ProductPage/Bracelets/Bracelets";
import Chains from "./Screens/ProductPage/Chains/Chains";
import Earrings from "./Screens/ProductPage/Earrings/Earrings";
import Rings from "./Screens/ProductPage/Rings/Rings";
import Watches from "./Screens/ProductPage/Watches/Watches";
import Invoice from "./Screens/Invoice";
import LoginAuth from "./Screens/Loginauth";
import B2BInvoice from "./Screens/BusinessInvoice";

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

function Main() {
  const location = useLocation();

  return (
    <>
{(location.pathname !== "/invoice" && location.pathname !== "/b2b") && (
        <>
          <Navbar />
        </>
      )}
      

      <main>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={Login} />
          <Route path="/pendants" exact component={Pendants} />
          <Route path="/bracelets" exact component={Bracelets} />
          <Route path="/watches" exact component={Watches} />
          <Route path="/rings" exact component={Rings} />
          <Route path="/earrings" exact component={Earrings} />
          <Route path="/chains" exact component={Chains} />
          
          <Route path="/invoice">
            <LoginAuth>
              <Invoice />
            </LoginAuth>
          </Route>
          <Route path="/b2b">
            <LoginAuth>
              <B2BInvoice />
            </LoginAuth>
          </Route>
        </Switch>
      </main>

      {(location.pathname !== "/invoice" && location.pathname !== "/b2b") && (
        <>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
