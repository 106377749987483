import React, { useState } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Container, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
const Invoice = () => {
  const [businessDetails, setBusinessDetails] = useState({
    name: "Sunil Jewellers",
    address: "pipa wali gali",
    city: "Kaisarganj, 271903",
    phone: "phone number - 9839454477, 8188888158",
  });
    const [invoiceData, setInvoiceData] = useState({
      invoiceNo: "",
      invoiceDate: "",
      gstinNo: "",
      from: { name: "Sunil Jewellers", address: "Purani bazaar pipal wali gali Kaisarganj 271903,9839454477,8188899158" },
      to: { name: "", email: "" },
      items: [],
      discount: 0,
      currency: "INR",
      gstPercentage: 0,
      exchangeItems: [],
      isFullPayment: true, // New field
      paidAmount: 0, // New field for paid amount
      
    });
  
  const [newItem, setNewItem] = useState({ description: "", qty: '', rate: '', makingCharges: '', material: "silver" });
  const [newExchangeItem, setNewExchangeItem] = useState({ description: "", qty: '', rate: '', purity: '', material: "gold" }); // New state for exchange item

  const handleInputChange = (e) => {
    setInvoiceData({ ...invoiceData, [e.target.name]: e.target.value });
  };

  const handleItemChange = (e) => {
    setNewItem({
      ...newItem,
      [e.target.name]: e.target.name === "qty" || e.target.name === "rate" || e.target.name === "makingCharges" ? Number(e.target.value) : e.target.value,
    });
  };

  const addItem = () => {
    if (newItem.description && newItem.qty > 0 && newItem.rate > 0) {
      const totalMakingCharges = newItem.makingCharges * newItem.qty;
      setInvoiceData({
        ...invoiceData,
        items: [
          ...invoiceData.items,
          { ...newItem, amount: newItem.qty * newItem.rate + totalMakingCharges },
        ],
      });
      setNewItem({ description: "", qty: 1, rate: 0, makingCharges: 0, material: "silver" });
    }
  };

  const handleExchangeItemChange = (e) => { // Handler for exchange item changes
    setNewExchangeItem({
      ...newExchangeItem,
      [e.target.name]: e.target.name === "qty" || e.target.name === "rate" || e.target.name === "purity" ? Number(e.target.value) : e.target.value,
    });
  };

  const addExchangeItem = () => { // Function to add exchange item
    if (newExchangeItem.description && newExchangeItem.qty > 0 && newExchangeItem.rate > 0 && newExchangeItem.purity > 0) {
      setInvoiceData({
        ...invoiceData,
        exchangeItems: [
          ...invoiceData.exchangeItems,
          { ...newExchangeItem, amount: (newExchangeItem.qty * newExchangeItem.rate * newExchangeItem.purity) / 100 }, // Amount calculation considering purity
        ],
      });
      setNewExchangeItem({ description: "", qty: 1, rate: 0, purity: 0, material: "gold" });
    }
  };


  const handleGSTPercentageChange = (e) => {
    setInvoiceData({ ...invoiceData, gstPercentage: Number(e.target.value) });
  };

  const handleDiscountChange = (e) => {
    setInvoiceData({ ...invoiceData, discount: e.target.value });
  };


  const calculateTotal = () => {
    const subtotal = invoiceData.items.reduce((acc, item) => acc + item.amount, 0);
    const exchangeSubtotal = invoiceData.exchangeItems.reduce((acc, item) => acc + item.amount, 0);
  
    let discountAmount = 0;
    if (invoiceData.isPercentageDiscount) {
      discountAmount = parseFloat((subtotal * invoiceData.discount) / 100);
    } else {
      discountAmount = parseFloat(invoiceData.discount);
    }
  
    const gstAmount = (subtotal - discountAmount - exchangeSubtotal) * (invoiceData.gstPercentage / 100);
    const total = subtotal - discountAmount - exchangeSubtotal + gstAmount;
  
    const amountPaid = invoiceData.isFullPayment ? total : invoiceData.paidAmount;
    const pendingAmount = total - amountPaid;
  
    return { subtotal, discountAmount, gstAmount, total, exchangeSubtotal, amountPaid, pendingAmount };
  };
  const handleDiscountTypeChange = (e) => {
    setInvoiceData({
      ...invoiceData,
      isPercentageDiscount: e.target.checked,
      discount: 0
    });
  };

  const removeItem = (indexToRemove) => {
    setInvoiceData({
      ...invoiceData,
      items: invoiceData.items.filter((_, index) => index !== indexToRemove),
    });
  };

  const removeExchangeItem = (indexToRemove) => {
    setInvoiceData({
      ...invoiceData,
      exchangeItems: invoiceData.exchangeItems.filter((_, index) => index !== indexToRemove),
    });
  };


  const handlePaidAmountChange = (e) => {
    const paidAmount = Number(e.target.value);
    const total = calculateTotal().total;
    setInvoiceData({
      ...invoiceData,
      paidAmount,
      pendingAmount: total - paidAmount,
    });
  };
  
  // Update the pending amount when isFullPayment changes
  const handleFullPaymentChange = (e) => {
    const isFullPayment = e.target.checked;
    const total = calculateTotal().total;
    setInvoiceData({
      ...invoiceData,
      isFullPayment,
      paidAmount: isFullPayment ? total : invoiceData.paidAmount,
      pendingAmount: isFullPayment ? 0 : total - invoiceData.paidAmount,
    });
  };

  const { subtotal, discountAmount, gstAmount, total, exchangeSubtotal, amountPaid,pendingAmount } = calculateTotal();

  const downloadPDF = () => {
    const pdf = new jsPDF('portrait', 'mm', 'a5'); // Set A5 size in portrait mode
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
  
    // Adjust the font sizes and positions to fit A5
    pdf.setFontSize(28);
    pdf.setFont("helvetica", "bold");
    pdf.text(businessDetails.name, 10, 20); // Business name
    pdf.setFontSize(10);
    pdf.setFont("helvetica", "normal");
    pdf.text(businessDetails.address, 12, 26); // Address
    pdf.text(businessDetails.city, 12, 30); // City
    pdf.text(businessDetails.phone, 12, 34); // Phone number
  
    pdf.setFontSize(10);
    pdf.text("Date:", pageWidth - 35, 20);
    pdf.setFont('helvetica', 'bold');
    pdf.text(`${invoiceData.invoiceDate}`, pageWidth - 35, 25);
 
    // "Billed To" Section - Elegant box design
    const billedToX = 12;
    const billedToY = 35;
    const cardWidth = pageWidth - 24;
    const cardHeight = 30;
    pdf.setDrawColor(255, 255, 255); // Light gray border
    pdf.rect(billedToX, billedToY, cardWidth,20); // Draw box
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(12);
    pdf.text('Customer Info', billedToX + 0, billedToY + 8);
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'normal');
    pdf.text(invoiceData.to.name, billedToX + 0, billedToY + 12);
    pdf.text(invoiceData.to.email, billedToX + 0, billedToY + 16);
  
    pdf.autoTable({
      startY: billedToY +  21,
      head: [["Item & Description", "Qty (g)", "Material", "Rate", "Making Charges", "Amount"]],
      body: invoiceData.items.map(item => [item.description, item.qty, item.material, item.rate, item.makingCharges, item.amount]),
      theme: 'grid',
      headStyles: { 
          fillColor: [139, 0, 0], 
          textColor: [255, 255, 255], 
          borderRadius: 10 // Apply rounded corners to the header
      },
    
      alternateRowStyles: {
        fillColor: [230, 230, 230],
      },
      margin: { top: 1, left: 10, right: 10, bottom: 10 },
      tableWidth: pageWidth - 20,
     
    });
  
    let finalY = pdf.autoTable.previous.finalY;
    if (invoiceData.exchangeItems.length > 0) {
      pdf.autoTable({
        startY: finalY + 3,
        head: [["Exchange Item & Description", "Material", "Amount"]],
        body: invoiceData.exchangeItems.map(item => [item.description,item.material, item.amount.toFixed(2)]),
        theme: 'grid',
      
        alternateRowStyles: {
          fillColor: [230, 230, 230],
        },
        margin: { top: 1, left: 10, right: 10, bottom: 10 },
      tableWidth: pageWidth - 20,
     
      });
      finalY = pdf.autoTable.previous.finalY;
    }
  
// Right side content
finalY += 10;
pdf.setFont('helvetica', 'bold');
pdf.setFontSize(10);
pdf.text(`Sub Total: ${invoiceData.currency} ${subtotal.toFixed(2)}`, pageWidth - 50, finalY);

if (invoiceData.exchangeItems.length > 0) {
  finalY += 5;
  pdf.setFont('helvetica', 'bold');
  pdf.setTextColor(0, 0, 130); // Blue
  pdf.text(`old Item: ${invoiceData.currency} ${exchangeSubtotal.toFixed(2)}`, pageWidth - 50, finalY);
  pdf.setTextColor(0, 0, 0); // Reset text color
}

pdf.setFont('helvetica', 'normal');
// invoiceData.items.forEach((item, index) => {
//   finalY += 5;
//   pdf.text(`Making Charges: ${item.makingCharges}`, pageWidth - 50, finalY);
// });

pdf.setFont('helvetica', 'bold');
pdf.setTextColor(139, 0, 0);
if (invoiceData.gstPercentage > 0) {
  finalY += 5;
  pdf.text(`GST( ${invoiceData.gstPercentage}%): ${invoiceData.currency} ${gstAmount.toFixed(2)}`, pageWidth - 50, finalY);

}

pdf.setFont('helvetica', 'bold');
pdf.setTextColor(0, 128, 0);
if (invoiceData.discount > 0) {
  finalY += 5;
  pdf.text(`Discount: ${invoiceData.currency} ${discountAmount.toFixed(2)}`, pageWidth - 50, finalY);
}
pdf.setFont('helvetica', 'bold');

pdf.setTextColor(0, 0, 0);
pdf.text(`_______________________`, pageWidth - 50, finalY + 2);
finalY += 8;
pdf.setFontSize(11);
pdf.text(`Total: ${invoiceData. currency} ${total.toFixed(2)}`, pageWidth - 50, finalY);
if (!invoiceData.isFullPayment) {


finalY += 6;
pdf.setFont('helvetica', 'bold');
pdf.setTextColor(255, 0, 0); // Red for pending amount
pdf.setFontSize(10);
pdf.text(`Pending amt: ${invoiceData.currency} ${pendingAmount.toFixed(2)}`, pageWidth - 50, finalY);
pdf.setFont('helvetica', 'bold');
pdf.setTextColor(0, 128, 0);
pdf.text(`Paid amt: ${invoiceData.currency} ${amountPaid.toFixed(2)}`, pageWidth - 50, finalY + 6);

pdf.setTextColor(0, 0, 0); // Reset text color
}

pdf.text("Authorized Signature", pageWidth - 40, finalY +30);

    // Terms and Conditions, Transaction details
 // Left side content
 pdf.setFontSize(8);
 if (!invoiceData.isFullPayment) {
  finalY += 2;
 }
 else
 finalY+=15

pdf.setFont('helvetica', 'bold');
pdf.setTextColor(139, 0, 0);
pdf.text("Terms and Conditions", 5, finalY-25 );
pdf.setFont('helvetica', 'normal');
pdf.setTextColor(0, 0, 0);
pdf.text("Thank you for doing business with us", 5, finalY-15);

pdf.setFont('helvetica', 'bold');
pdf.setTextColor(139, 0, 0);
pdf.text("Transaction Details", 5, finalY - 5);
pdf.setFont('helvetica', 'normal');
pdf.setTextColor(0, 0, 0);

pdf.text("Ifsc Code: HDFC004079", 5, finalY + 0);
pdf.text("Account Number: 50200024742873", 5, finalY + 5);
pdf.text("Beneficiary: Sunil Jewellers", 5, finalY  + 10);
pdf.text("Bank: HDFC bank", 5, finalY   + 15); 
pdf.text("Branch: Kaisarganj", 5, finalY + 20);

// Contact information at the bottom
pdf.setFont('helvetica', 'normal');
pdf.text("For any enquiries, email us on ", 5, finalY +30);

pdf.setFont('helvetica', 'bold');
pdf.text("kailashnnathsarraf.in", 2 + pdf.getTextWidth("For any enquiries, email us on "), finalY + 30);

  
    pdf.save(`invoice_${invoiceData.invoiceNo}.pdf`);
  };
  



 
  return (
    <Container mt={3} sx={{marginBottom:5}}>
    <Box
      sx={{
        margin: 'auto',
        backgroundColor: '#ffffff',
        padding: 4,
        boxShadow: 5,
        borderRadius: 5,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" fontWeight="bold" color="primary">
            Estimate
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="right">
          <Typography variant="h6" sx={{ color: "#ff5722" }}>
            {invoiceData.invoiceNo}
          </Typography>
        </Grid>
      </Grid>
      <FormControl fullWidth margin="dense">
  <InputLabel>Select Business Name</InputLabel>
  <Select
    value={businessDetails.name}
    onChange={(e) => {
      const selectedBusiness = e.target.value;
      if (selectedBusiness === "Sunil Jewellers") {
        setBusinessDetails({
          name: "Sunil Jewellers",
    address: "pipa wali gali",
    city: "Kaisarganj, 271903",
    phone: "phone number - 9839454477, 8188888158",
        });
      } else if (selectedBusiness === "Kailashnath Sarraf") {
        setBusinessDetails({
          name: "Kailashnath Sarraf",
          address: "sarkari asptal ke dai taraf",
          city: "main road kaisarganj, 271904",
          phone: "phone number - 9415578431, 9839454477",
        });
      }
    }}
    label="Select Business Name"
  >
    <MenuItem value="Sunil Jewellers">Sunil Jewellers</MenuItem>
    <MenuItem value="Kailashnath Sarraf">Kailashnath Sarraf</MenuItem>
  </Select>
</FormControl>
      {/* ... (rest of the header and From/To sections remain the same) */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Estimate Date"
            type="date"
            name="invoiceDate"
            value={invoiceData.invoiceDate}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            sx={{
              borderRadius: '10px',
              '& .MuiInputLabel-root': {
                color: '#555', // Customize label color
              },
              '& .MuiInputBase-root': {
                backgroundColor: '#fff', // Set background color of the input field
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ddd', // Change border color
                },
                '&:hover fieldset': {
                  borderColor: '#1976d2', // Change border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2', // Focused border color
                },
              },
            }}
            InputLabelProps={{
              shrink: true, // Ensures the label is always above the input
            }}
            InputProps={{
              placeholder: 'Select a date', // Placeholder text
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="GSTIN No (Optional)"
            name="gstinNo"
            value={invoiceData.gstinNo}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>From</Typography>
          <TextField
            label="Name"
            value={invoiceData.from.name}
            onChange={(e) =>
              setInvoiceData({
                ...invoiceData,
                from: { ...invoiceData.from, name: e.target.value },
              })
            }
            fullWidth
            margin="dense"
          />  
          <TextField
            label="Address"
            value={invoiceData.from.address}
            onChange={(e) =>
              setInvoiceData({
                ...invoiceData,
                from: { ...invoiceData.from, address: e.target.value },
              })
            }
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>Customer Details</Typography>
          <TextField
            label="Name"
            value={invoiceData.to.name}
            onChange={(e) =>
              setInvoiceData({
                ...invoiceData,
                to: { ...invoiceData.to, name: e.target.value },
              })
            }
            fullWidth
            margin="dense"
            required
          />
          <TextField
            label="Customer Email PhoneNumber Address(optional)"
            value={invoiceData.to.email}
            onChange={(e) =>
              setInvoiceData({
                ...invoiceData,
                to: { ...invoiceData.to, email: e.target.value },
              })
            }
            fullWidth
            margin="dense"
          />
        </Grid>
      </Grid>


      <Typography variant="h6" mt={2}>
        Items
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={newItem.description}
            onChange={handleItemChange}
            fullWidth
            margin="dense"
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Quantity (grams)"
            name="qty"
            type="number"
            value={newItem.qty}
            onChange={handleItemChange}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Rate"
            name="rate"
            type="number"
            value={newItem.rate}
            onChange={handleItemChange}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Making Charges"
            name="makingCharges"
            type="number"
            value={newItem.makingCharges}
            onChange={handleItemChange}
            fullWidth
            margin="dense"
          />
        </Grid>
      </Grid>
      <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
        <InputLabel sx={{ fontWeight: 600, color: 'text.primary' }}>Material</InputLabel>
        <Select
          value={newItem.material}
          onChange={handleItemChange}
          name="material"
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,


          }}
        >
          <MenuItem value="silver">Silver</MenuItem>
          <MenuItem value="gold">Gold</MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={addItem}
        sx={{
          mt: 2,
          mb: 2,
          background: "linear-gradient(45deg, #ff5722, #ff7043)", // Gradient color
          color: "white",
          width: "100%",
          borderRadius: "8px", // Rounded corners
          fontWeight: "bold",  // Bolder font for emphasis
          fontSize: "16px",    // Slightly larger font size for better readability
          padding: "12px 24px", // Increased padding for a more spacious button
          boxShadow: 2,        // Soft shadow to give depth
          textTransform: "none", // Prevent text from being capitalized
          transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
          '&:hover': {
            background: "linear-gradient(45deg, #ff7043, #ff5722)", // Reversed gradient on hover
            boxShadow: 3, // Slightly more prominent shadow on hover
          },
          '&:active': {
            background: "#e64a19", // Darker color when clicked
            boxShadow: "none", // Remove shadow when clicked for a pressed effect
          },
        }}
      >
        Add Item
      </Button>
      {invoiceData.items.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Quantity (g)</TableCell>
                <TableCell>Material</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Making Charges</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.material}</TableCell>
                  <TableCell>{item.rate}</TableCell>
                  <TableCell>{item.makingCharges}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>
                    <Button onClick={() => removeItem(index)} color="error">Remove</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Exchange Items Section */}
      <Typography variant="h6" mt={2}>
        Exchange Items
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={newExchangeItem.description}
            onChange={handleExchangeItemChange}
            fullWidth
            margin="dense"
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Quantity (grams)"
            name="qty"
            type="number"
            value={newExchangeItem.qty}
            onChange={handleExchangeItemChange}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Purity (%)"
            name="purity"
            type="number"
            value={newExchangeItem.purity}
            onChange={handleExchangeItemChange}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Rate"
            name="rate"
            type="number"
            value={newExchangeItem.rate}
            onChange={handleExchangeItemChange}
            fullWidth
            margin="dense"
          />
        </Grid>
         <Grid item xs={12} sm={3}>
        <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
        <InputLabel sx={{ fontWeight: 600, color: 'text.primary' }}>Material</InputLabel>
        <Select
          value={newExchangeItem.material}
          onChange={handleExchangeItemChange}
          name="material"
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,


          }}
        >
          <MenuItem value="gold">Gold</MenuItem>
          <MenuItem value="silver">Silver</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      </Grid>
      </Grid>
      <Button
        onClick={addExchangeItem}
        sx={{
          mt: 2,
          mb: 2,
          background: "linear-gradient(45deg, #4caf50, #8bc34a)", // Green gradient for exchange button
          color: "white",
          width: "100%",
          borderRadius: "8px",
          fontWeight: "bold",
          fontSize: "16px",
          padding: "12px 24px",
          boxShadow: 2,
          textTransform: "none",
          transition: "all 0.3s ease-in-out",
          '&:hover': {
            background: "linear-gradient(45deg, #8bc34a, #4caf50)", // Reversed gradient on hover
            boxShadow: 3,
          },
          '&:active': {
            background: "#388e3c", // Darker color when clicked
            boxShadow: "none",
          },
        }}
      >
        Add Exchange Item
      </Button>

      {invoiceData.exchangeItems.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Quantity (g)</TableCell>
                <TableCell>Purity (%)</TableCell>
                <TableCell>Material</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.exchangeItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.purity}</TableCell>
                   <TableCell>{item.material}</TableCell>
                  <TableCell>{item.rate}</TableCell>
                  <TableCell>{item.amount.toFixed(2)}</TableCell>
                  <TableCell>
                    <Button onClick={() => removeExchangeItem(index)} color="error">Remove</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}


      <Grid container spacing={2}>
        {/* Discount Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ mb: 1 }}>Discount</Typography>

          {invoiceData.isPercentageDiscount ? (
            <Select
              value={invoiceData.discount}
              onChange={handleDiscountChange}
              fullWidth
              margin="dense"
              sx={{
                borderRadius: 1,
                boxShadow: 1,
                backgroundColor: 'background.paper',
                mb: 2,
              }}
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={5}>5%</MenuItem>
              <MenuItem value={10}>10%</MenuItem>
              <MenuItem value={15}>15%</MenuItem>
            </Select>
          ) : (
            <TextField
              label="Discount Amount"
              value={invoiceData.discount}
              onChange={handleDiscountChange}
              fullWidth
              margin="dense"
              type="number"
              sx={{
                borderRadius: 1,
                boxShadow: 1,
                backgroundColor: 'background.paper',
                mb: 2,
              }}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={invoiceData.isPercentageDiscount}
                onChange={handleDiscountTypeChange}
                name="discountType"
                color="primary"
              />
            }
            label="Discount in Percentage"
            sx={{}}
          />
          <Tooltip title="Check if want to add % discount" arrow>
            <span style={{ display: 'block', fontSize: '0.8rem', color: '#888' }}>Check if want to add % discount</span>
          </Tooltip>
        </Grid>
        {/* GST Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ mb: 1 }}>GST Percentage</Typography>
          <TextField
            label="GST (%)"
            value={invoiceData.gstPercentage}
            onChange={handleGSTPercentageChange}
            fullWidth
            margin="dense"
            type="number"
            sx={{
              borderRadius: 1,
              boxShadow: 1,
              backgroundColor: 'background.paper',
              mb: 2,
            }}
          />
        </Grid>
      </Grid>
      {/* Subtotal and Total Section */}
      <Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
    <FormControlLabel
      control={
        <Checkbox
          checked={invoiceData.isFullPayment}
          onChange={handleFullPaymentChange}
          name="isFullPayment"
          color="primary"
        />
      }
      label="Full Payment"
    />
      <Tooltip title="Check this box if the payment is full">
      <span style={{ display: 'block', fontSize: '0.8rem', color: '#888' }}>Check this box if the payment is full</span>

  </Tooltip>
  </Grid>

  {!invoiceData.isFullPayment && (
  <Grid item xs={12} sm={6}>
      <TextField
        label="Paid Amount"
        name="paidAmount"
        type="number"
        value={invoiceData.paidAmount}
        onChange={handlePaidAmountChange}
        fullWidth
        margin="dense"
      />
          <Tooltip title="Enter the amount that has been paid">
          <span style={{ display: 'block', fontSize: '0.8rem', color: '#888' }}>Enter the amount that has been paid</span>

    </Tooltip>
  </Grid>
)}
</Grid>

<Grid container spacing={2} sx={{ mt: 2 }}>
  <Grid item xs={12} sm={4}>
    <Box
      sx={{
        borderRadius: 2,
        padding: 2,
        boxShadow: 2,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#e8f5e9', // Light green for amount paid
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        Amount Paid
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 700, color: '#2e7d32' }}>
        {invoiceData.currency} {amountPaid.toFixed(2)}
      </Typography>
    </Box>
  </Grid>

  {!invoiceData.isFullPayment && (
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          borderRadius: 2,
          padding: 2,
          boxShadow: 2,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: '#ffebee', // Light red for pending amount
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Pending Amount
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 700, color: '#d32f2f' }}>
          {invoiceData.currency} {pendingAmount.toFixed(2)}
        </Typography>
      </Box>
    </Grid>
  )}
</Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              borderRadius: 2,
              padding: 2,
              boxShadow: 2,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
              Sub Total
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 700, color: 'primary.main' }}>
              {invoiceData.currency} {subtotal.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        {/* Exchange Subtotal Section */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              borderRadius: 2,
              padding: 2,
              boxShadow: 2,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              backgroundColor: '#f0f7fa', // Light blue-gray for exchange subtotal
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
              Exchange Value
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 700, color: '#2e7d32' }}> {/* Green color for exchange value */}
              {invoiceData.currency} {exchangeSubtotal.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        {/* Total Section */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              // backgroundColor: 'primary.light',
              borderRadius: 2,
              padding: 2,
              boxShadow: 2,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
              Total
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 700, color: 'secondary.main' }}>
              {invoiceData.currency} {total.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center" mt={3}>
       
        <Grid item xs={12} sm="auto">
          <Button
            variant="contained"
            color="primary"
            onClick={downloadPDF}
            sx={{
              backgroundColor: '#ff9800',
              color: 'white',
              borderRadius: '10px',
              '&:hover': { backgroundColor: '#f57c00' },
              width: "100%",
            }}
          >
            Download Estimate
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Container>

);
};
export default Invoice;
